import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  modalStyle: {
    backgroundColor: theme.palette.primary.white,
    position: 'relative',
    width: '50%',
    left: '25%',
    marginTop: '15%',
    padding: theme.spacing(5),
    outline: 'none',
    height: '30vh',
    textAlign: 'center',
  },
  buttonPrimary: {
    marginTop: theme.spacing(5),
    fontWeight: 'bold',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.white + '!important',
    borderRadius: 0 + '!important',
    border: '1px solid #002C47 !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.white + '!important',
      color: theme.palette.primary.main + '!important',
    },
  },
});

function DenyModal({
  classes,
  denyModal,
  setDenyModal,
  denyModalId,
  setDenyModalId,
  brand,
}) {
  const reasons = [
    'Verkeerde prijs: excl. btw in plaats van incl. btw',
    'Kwaliteit van foto\'s voldoet niet aan eisen',
    'Verkeerde omschrijving',
    'Advertentie titel is onjuist',
    'Advertentie is onvolledig',
    'Ander automerk zichtbaar in advertentie',
    'Spellingsfout in tekst of omschrijving',
    'Dubbele advertentie',
    'Auto is nog niet op voorraad',
    'Auto mag nog niet geadverteerd worden',
    'Auto op foto\'s is anders dan omschreven auto',
    'Geselecteerde optie zit niet op deze auto',
    'Dealernaam is zichtbaar in hoofdfoto',
    'Verkeerde prijs: verplicht afleverpakket niet opgenomen in advertentieprijs',
    'Garantietermijn niet volgens voorwaarden',
    'Auto is reeds verkocht',
    'Verkeerde kleur auto geselecteerd',
  ];
  // Deny reason
  const [denyReason, setDenyReason] = useState('Verkeerde prijs: excl. btw in plaats van incl. btw');
  const handleDenyReason = (event) => {
    setDenyReason(event.target.value);
  };

  // Deny chosen advertisement
  function denyAdvertisement() {
    fetch(
        `${process.env.REACT_APP_API_URL}/sites/${brand}/advertisements/${denyModalId}`, {
          headers: new Headers({
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)}`,
            'Content-Type': 'application/json',
          }),
          method: 'PATCH',
          body: JSON.stringify({
            status: 'denied',
            statusReason: denyReason,
          }),
        }).then(() => {
      closeDenyModal();
    });
  }

  // Toggle deny modal
  const toggleDenyModal = () => setDenyModal(!denyModal);

  // Set deny modal ID
  const resetDenyModalId = () => setDenyModalId('');

  // Close advertisement modal
  function closeDenyModal() {
    toggleDenyModal();
    resetDenyModalId();
  }

  return (
    <Modal
      open={ denyModal }
      onClose={ closeDenyModal }
    >
      <div className={ classes.modalStyle }>
        <Grid container>
          <Grid item md={ 12 }>
            <h2>Please select your reason</h2>
          </Grid>
          <Grid item md={ 12 }>
            <FormControl>
              <Select
                value={ denyReason }
                onChange={ handleDenyReason }
                displayEmpty
              >
                { reasons.map(function(reason) {
                  return <MenuItem key={ reason } value={ reason }>{ reason }</MenuItem>;
                }) }
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={ 12 }>
            <Button
              className={ classes.buttonPrimary }
              onClick={ denyAdvertisement }
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(DenyModal);
