import {createMuiTheme} from '@material-ui/core/styles';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#F6F6F6',
      main: '#002C47',
      white: '#ffffff',
    },
  },
  spacing: 8,
});

// Okta configuration
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
console.log(ISSUER);
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;

export const oktaConfig = {
  issuer: ISSUER,
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: CLIENT_ID,
  /**
   * Proof Key for Code Exchange solution for protecting SPAs.
   *
   * PKCE is a security extension to OAuth 2.0 for public clients on mobile
   * devices, designed to prevent interception of the authorisation code.
   *
   * https://developer.okta.com/blog/2019/08/22/okta-authjs-pkce
   */
  pkce: true,
};

export default theme;
