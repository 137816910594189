import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Button,
} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '85vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 80,
    fontWeight: 700,
  },
});

function NotFound({classes, history}) {
  return (<Paper className={ classes.root }>
    <Typography
      component="span"
      variant="h1"
      color="textPrimary"
      className={ classes.title }
      align="center"
      display="block">
      404
      <Typography
        component="span"
        variant="h2"
        color="textPrimary"
        align="center"
        display="block">
        Page not found
      </Typography>
      <Button
        variant="contained"
        color="primary"
        aria-label="menu"
        onClick={ history.goBack }
      >
        Go Back
      </Button>
    </Typography>
  </Paper>);
}
export default withStyles(styles)(NotFound);
