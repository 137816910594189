import React from 'react';

function MainContent({children}) {
  return (
    <main>
      { children }
    </main>
  );
}

export default MainContent;
