import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import {Security, SecureRoute, ImplicitCallback} from '@okta/okta-react';
import theme, {oktaConfig} from './setting';

import MainContent from './components/MainContent';
import Overview from './views/Overview';
import NotFound from './views/NotFound';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
require('dotenv').config();

function App() {
  return (
    <ThemeProvider theme={ theme }>
      <CssBaseline />
      <Router>
        <Security { ...oktaConfig }>
          <MainContent>
            <Switch>
              <SecureRoute exact path='/' component={ Overview } />
              <Route
                path='/implicit/callback'
                component={ ImplicitCallback }
              />
              <Route component={ NotFound } />
            </Switch>
          </MainContent>
        </Security>
      </Router>
    </ThemeProvider>
  );
}
export default App;
